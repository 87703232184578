<template>
  <div class="invitation-details">
    <div class="header">
      <img src="../assets/logo.png"/>
      <div class="title">海葵分贝</div>
    </div>
    <div class="content">
      <img :src="isStateFn" alt="">
      <div class="title" v-if="state !== 0">
         {{title}}邀请你加入
      </div>
      <div class="title" v-else>
        链接已过期
        <div class="sub-title">
          该邀请链接已过期，请联系管理员获取新的链接
        </div>
      </div>
      <div class="but" :class="butClass" @click="joinFn" v-if="state !== 0"></div>
      <router-link to="/" v-else>
        <div class="but back-home"></div>
      </router-link>

      <div class="toolbar" v-if="state === 3|| state === 4||state === 5">
        <div class="left" @click="switchAccount">切换账号</div>
        <router-link to="/"  active-class='ative'> <div class="right">返回首页</div></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import invitation from '../assets/invitation.png';
import  underReview from '../assets/Invitation-under-review-logo.png';
import auditFailed from '../assets/Invitation-approval-failed-logo.png';
export default {
  name: "InvitationDetails",
  data(){
    return{
      invitation,//邀请
      underReview,//审核中
      auditFailed,//审核失败
      title:undefined,
      code:undefined,
      companyId:undefined,
      state:0,//链接已过期0,未登录:1  已登录:2，审核中：3，审核失败：4，加入失败：5
    }
  },
  computed:{
    butClass(){
      let s = this.state;
      if(s === 1){
        return 'but-confirm-login'
      }else if(s === 2){
        return 'but-confirm'
      }else if(s === 3){
        return 'but-under-review'
      }else if(s === 4){
        return 'but-audit-failed'
      }else {
        return 'but-join-failed'
      }
    },
    isStateFn(){
      switch (this.state){
        case 0:
          return auditFailed
        case 1:
          return invitation
        case 2:
          return invitation
        case 3:
          return underReview
        case 4:
          return auditFailed
        case 5:
          return auditFailed
        default:
          return invitation
      }
    },


  },
  created() {
    if(!localStorage.getItem('userInfo')){
      this.state = 1;
    }
    this.code = this.$route.params.code;
    this.getData();
  },
  methods:{
    getData(){
      this.$get(`/api/company/user/inviteCodeCheck?code=${this.code}`).then(r=>{
        if(r.code === 0){
          this.title = r.companyName;
          this.companyId = r.companyId;
          if(localStorage.getItem('userInfo')){
            this.state = 2
          }else {
            this.state = 1
          }
          if(this.$route.query.login === 'login'){
            this.userApply()
          }
        }else {
          this.state = 0
        }
      })
    },
    userApply(){
      console.log(this.$route.query.nickName, 'nickname');
      let userObj = JSON.parse(localStorage.getItem('userInfo')).user
      this.$post('/api/company/user/apply',{
        companyId: this.companyId,
        id: userObj.id,
        nickName:this.$route.query.nickName ? this.$route.query.nickName : undefined,
        code:this.code,
      }).then(r=>{
        // type : 0审核中 1审核通过 2审核拒绝 3已加入其他机构 4 申请异常
        switch (r.type){
          case 0:
            this.state = 3
           break;
          case 1:
           this.$router.push('/create')
            this.$post('/api/company/identityChange', {id:this.companyId,type:1}).then((res) => {
              let data = res.data
              if (res.code == 0) {
                this.$store.commit('LOGIN', {
                  secret: data.secret,
                  session: data.session,
                  user: data.user
                })
              }
            })
            break
          case 2:
            this.state = 4
            break
          case 3:
            this.state = 5
            break
         }

      })
    },
    joinFn(){
      if(this.state === 1){
        this.$router.push({path:'/login', query:{code:this.code,title:this.title}});
      }else if(this.state === 2){
          this.userApply()
      }else {
        return''
      }
    },
    switchAccount(){
      this.$store.commit('LOGOUT');
      this.$router.push({path:'/login', query:{code:this.code, title:this.title}});
    }
  }
}
</script>

<style scoped lang="scss">
.invitation-details {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: center;
    img{
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    .title{
      font-size: 28px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #333333;
      letter-spacing: 0.01px;

    }
  }
  .content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img{
      width: 440px;
      height: 400px;
    }
    .title{
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #333333;
      letter-spacing: 0.01px;
      margin-top: -130px;
      .sub-title{
        margin-top: 6px;
        font-size: 12px;
      }
    }
    .but{
      margin-top: 44px;
      width: 350px;
      height: 42px;
      border-radius: 3px;
      line-height:42px;
      text-align: center;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      letter-spacing: 0.01px;
    }
    .back-home{
      background: #ff004d;
      &::after{
        content: '返回首页';
        color: #ffffff;
      }
    }
    .but-confirm{
      background: #ff004d;
      cursor: pointer;
      &::after{
        content: '加入';
        color: #ffffff;
      }
    }
    .but-confirm-login{
      background: #ff004d;
      cursor: pointer;
      &::after{
        content: '登录并加入';
        color: #ffffff;
      }
    }
    .but-under-review{
      background: #f7f7f7;
      &::after{
        content: "管理员审核中...";
        color: #666666;
      }
    }
    .but-audit-failed{
      background-color: #f7f7f7;
      &::after{
        content: '抱歉，审核未通过，您无法加入';
        color: #666666;
      }
    }
    .but-join-failed{
      background-color: #f7f7f7;
      &::after{
        content: '抱歉，您已加入该机构请勿重新添加。';
        color: #666666;
      }
    }
    .toolbar{
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #999999;
      letter-spacing: 0.01px;
      display: flex;
      margin-top: 28px;
      cursor: pointer;
      .left{
         margin-right: 50px;
      }
    }
  }
}
a{

  text-decoration: none;
  color: #999999;
  font-family:sans-serif;
}
</style>
